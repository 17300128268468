import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as S from './styled';


const DoctorGrid = props => {

  const data = useStaticQuery(graphql`
    {
      allFile {
        nodes {
          childImageSharp {
            fixed(quality: 90) {
              originalName
              ...GatsbyImageSharpFixed
            }            
          }
        }
      }
      site {
        siteMetadata {
          doctors {
            name
            registry
            photo
          }
        }
      }
    }
  `);

  function getPhoto(name) {
    const image = data.allFile.nodes.find(({ childImageSharp: item }) => {
      return item.fixed.originalName === name;
    });
    return image ? image.childImageSharp.fixed : null;
  }

  return (
    <S.DoctorGrid>
      {data.site.siteMetadata.doctors.map((doctor, index) => (
      <S.DoctorItem key={`doctor-${index}`}>
        <S.DoctorName>{doctor.name}</S.DoctorName>
        <S.DoctorRegistry>{`CROPR ${doctor.registry}`}</S.DoctorRegistry>
          <S.DoctorPhoto
          fixed={getPhoto(doctor.photo)}
          height={315}
          width={260}
        ></S.DoctorPhoto>
      </S.DoctorItem>
      ))}
    </S.DoctorGrid>
  )
}

export default DoctorGrid;